<template>
  <div>
    <NavigationBar name="注册审核"/>
    <div class="content">
      <div v-for="(item,index) of listData" :key="index" class="card-content">
        <md-steps :steps="steps" :current=" item.user_status == 2 ? 0 : 1"/>
        <div class="info-content">
          <div class="flexbox">
            <p class="text-title">邀请ID</p>
            <p>{{ item.id }}</p>
          </div>
          <div class="flexbox margin-top">
            <p class="text-title">状态</p>
            <p>{{ item.user_status | userStatus }}</p>
          </div>
          <div class="flexbox margin-top">
            <p class="text-title">邀请时间</p>
            <p>{{ item.create_time }}</p>
          </div>
          <div class="flexbox margin-top">
            <p class="text-title">姓名</p>
            <p>{{ item.name }}</p>
          </div>
          <div class="flexbox margin-top">
            <p class="text-title">手机号</p>
            <p>{{ item.mobile }}</p>
          </div>
        </div>
        <div class="detail-btn" @click="detailInfo(item.id)">查看详情</div>
      </div>
    </div>
  </div>


</template>

<script>
import {Button, Steps} from 'mand-mobile'

export default {
  name: "InviteAudit",
  components: {
    [Button.name]: Button,
    [Steps.name]: Steps
  },
  mounted() {
    this.getUserList()
  },
  filters: {
    userStatus(data) {
      switch (data) {
        case 1:
          return '审核通过'
        case 2:
          return '待审核'
        case 0:
          return '不通过'
        default :
          return data
      }
    }
  },
  methods: {
    // 查看详情
    detailInfo(id) {
      this.$router.push({
        path:'/inviteDetail',
        query:{
          id
        }
      })
    },
    getUserList() {
      this.$API.register.userRegisterList().then(res => {
        console.log(res)
        this.listData = res.data.data
      })
    }
  },
  data() {
    return {
      steps: [
        {
          name: '待审核',
        },
        {
          name: '已审核',
        }
      ],
      listData: []
    }
  },

}
</script>

<style scoped>
.card-content {
  margin: 0 2%;
  margin-top: 50px;
  box-shadow: 0 4px 8px 0 rgba(50, 50, 50, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  background-color: white;
}

.info-content {
  padding: 0 40px;
  font-size: 30px;
  padding-right: 50px;
}

.info-content div:not(:first-child) p {
  color: rgb(146, 146, 146);
}

.flexbox {
  display: flex;
  justify-content: space-between;
}

.text-title {
  width: 160px;
  text-align: center;
}

.margin-top {
  margin-top: 30px;
}

.detail-btn {
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
  line-height: 100px;
  color: rgb(47, 134, 246);
}
</style>
